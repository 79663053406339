import useLocalStorage from '@hooks/useLocalStorage';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

export function Versions() {
  const [versionAlert, setVersionAlert] = useLocalStorage('versionALert', true);
  const userAgent = window.navigator.userAgent.includes('WhatsMenuDesktop/1.5.12');
  function handleCLick() {
    setVersionAlert(false);
  }

  return (
    <>
      {versionAlert && !userAgent && (
        <>
          <Alert variant="warning" dismissible>
            <Alert.Heading>Novo WhatsMenu Desktop</Alert.Heading>
            <p>Mais leve e mais rápido - Maior eficiente nos controles de mensagens.</p>
            <Button variant="primary"
              // href="https://whatsmenu-desktop-update-server.vercel.app/download"
              href="https://github.com/Grove-Company/.github/releases/download/v1.5.12/WhatsMenu.Desktop-1.5.12.Setup.exe"
              onClick={handleCLick}>
              ATUALIZE AGORA
            </Button>
          </Alert>
        </>
      )}
    </>
  );
}